<template>
  <div>
    <exp-alert-confirm
      ref="modalSaveChanges"
      :message="msg_modal"
      :labelOk="'core.comun.yes'"
      :colorOk="'green'"
      :iconOk="'mdi-check-circle'"
      :fnOk="saveReportSatus"
      :labelCancel="'core.comun.no'"
      :colorCancel="'red'"
      :iconCancel="'mdi-close-circle'"
      :fnCancel="dontSaveChange"
    />
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <exp-loading v-show="isLoading" />
      <v-card :disabled="saving">
        <v-tabs style="margin-left: 10px;" white><b style="margin-top: 10px; color:black">{{ $t("core.comun.general_information") }}</b></v-tabs>
        <v-tabs v-model="tab" dark>
          <v-tab>{{ $t("ore.report.view_comments") }}</v-tab>
          <v-tab v-show="currentStatus == 1 || currentStatus == 3" v-if="this.userType <= 4"
            >{{ $t("ore.report.reopen_report") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="min-height: 200px">
          <v-tab-item>
            <div class="mt-5">
              <v-card-text class="mt-0 pt-0">
                <v-list
                  dense
                  rounded
                  color="white"
                  style="height: 414px; overflow: auto; padding: 0px !important"
                >
                  <v-list-item
                    v-for="item in items"
                    :key="item.id"
                    class="px-2"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="item.created"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="item.comment"
                        :style="{ whiteSpace: 'pre-wrap' }"
                      ></v-list-item-subtitle>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded @click="showDialog = false" color="red">
                  <v-icon right>mdi-close-circle</v-icon>
                  {{ $t("core.comun.cancel") }}&nbsp;
                </v-btn>
              </v-card-actions>
            </div>
          </v-tab-item>
          <v-tab-item>
            <ValidationObserver v-slot="{ dirty }" ref="observerComments">
              <form
                class="form-inventory mt-5"
                @submit.prevent="saveReportSatus()"
              >
                <v-card-text>
                  <v-row justify="space-between">
                    <v-col cols="12" class="py-0">
                      <ValidationProvider
                        v-slot="{ errors }"
                        :name="$t('ore.report.comments')"
                        rules="required"
                      >
                        <v-textarea
                          rows="3"
                          outlined
                          :error-messages="errors"
                          v-model="data.comment"
                          dense
                          :label="$t('ore.report.comments')"
                          value=""
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn rounded :disabled="!dirty" type="submit" color="green">
                    <v-icon left v-if="!saving">mdi-content-save</v-icon>
                    <v-progress-circular
                      v-else
                      indeterminate
                      size="24"
                      class="ore-progress-circular-loading"
                    ></v-progress-circular>
                    {{ $t("core.comun.save") }}&nbsp;
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn rounded @click="cancelBtnForm()" color="red">
                    <v-icon left>mdi-close-circle</v-icon>
                    {{ $t("core.comun.cancel") }}&nbsp;
                  </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { saveUpdateReportSatus } from "@/gateway/report";
import expReportTable from "@/components/expReportTable";
export default {
  name: "modal-add-comments-index",
  mixins: [basicMixin, oreMixin],
  components: {
    expReportTable,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      showDialog: false,
      saving: false,
      msg_modal: "",
      tab: 0,
      currentStatus: 0,
      reportId: 0,
      data: {
        comments: "",
      },
      items: [],
      headers: [
        {
          text: "ore.report.date",
          value: "created",
          width: "80px",
          alignh: "text-center",
          alignb: "text-center",
        },
        {
          text: "ore.report.comments",
          value: "comment",
          width: "80px",
          alignh: "text-center",
          alignb: "text-center",
        },
      ],
    };
  },
  methods: {
    async saveReportSatus() {
      const self = this;
      let valid = await self.$refs.observerComments.validate();
      if (valid) {
        self.saving = true;
        self.data.report = self.reportId;
        self.data.status = 3;
        await saveUpdateReportSatus(self.data);
        self.$emit("change");
        self.showDialog = false;
        self.saving = false;
      }
    },
    async cancelBtnForm() {
      const self = this;
      if (self.isDirty) {
        self.msg_modal = "ore.report.msg_data_no_save";
        self.$refs.modalSaveChanges.showDialog = true;
      } else {
        self.showDialog = false;
      }
    },
    async dontSaveChange() {
      this.$refs.modalSaveChanges.showDialog = false;
      this.showDialog = false;
    },
  },
  computed: {
    isDirty() {
      if (this.currentStatus == 1) {
        return this.$refs.observerComments.ctx.dirty;
      } else {
        return false;
      }
    },
  },
  watch: {
    showDialog(isOpen) {
      if (isOpen == true) {
        this.data = {
          comments: "",
          report: null,
        };
        setTimeout(() => {
          if (this.$refs.observerComments != undefined)
            this.$refs.observerComments.reset();
        }, 100);
      }
    },
  },
};
</script>
