<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="700px">
      <v-card>
        <v-card-title class="headline lighten-2 pb-0"> </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center" width="100">
                    {{ $t("ore.report.reason") }}
                  </th>
                  <th class="text-left" width="100">
                    {{ $t("ore.report.equipment") }}
                  </th>
                  <th class="text-center" width="100">
                    {{ $t("ore.report.stop_time") }}
                  </th>
                  <th class="text-center" width="100">
                    {{ $t("ore.report.restart_time") }}
                  </th>
                  <th class="text-center" width="100">
                    {{ $t("ore.report.comments") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in allData" :key="index">
                  <td>{{ reasonName(item.razon) }}</td>
                  <td class="col_custom"> {{ equipmentName(item.equipment) }} </td>
                  <td class="text-center col_custom">
                    {{ $moment(item.stop_time, "YYYY-MM-DDTHH:mm:ss").format(crusherFormat.format_date + ' HH:mm') }}<br />
                  </td>
                  <td class="text-center">
                    <span v-if="item.restart_time">
                      {{ $moment(item.restart_time, "YYYY-MM-DDTHH:mm:ss").format(crusherFormat.format_date + ' HH:mm') }}
                    </span>
                    <span v-else> - - - </span>
                  </td>
                  <td class="text-left">
                    {{ item.comments }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn rounded @click="showDialog = false" color="red">
            <v-icon left>mdi-close-circle</v-icon>
            {{ $t("core.comun.close") }}&nbsp;
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import oreMixin from "@/mixins/ore";
import basicMixin from "@/mixins/basic";
import { getListItemsByCode } from "@/gateway/lists";

export default {
  name: "modal_bitumen_reception",
  mixins: [basicMixin, oreMixin],
  data() {
    return {
      showDialog: false,
      report: {},
      allData: [],
      listReason: [],
      // configFormsEvents: {
      //   stop_time: { visible: true, disabled: false },
      //   unresolved: { visible: true, disabled: false },
      //   restart_time: { visible: true, disabled: false },
      //   razon: { visible: true, disabled: false },
      //   category: { visible: false, disabled: false },
      //   equipment: { visible: false, disabled: false },
      //   equipment_id:{ visible: false, disabled: false},
      //   comments: { visible: true, disabled: false },
      // },
      
    };
  },
  mounted() {
    // let storeConfigModule = this.pageModuleConfig.settingsConfig;
    // let localConfigFormEvents = this.$_.clone(this.configFormsEvents);
    // this.configFormsEvents = {
    //   ...localConfigFormEvents,
    //   ...storeConfigModule.formReception,
    // };
  },
  methods: {
    reservoirName(id) {
      try {
        if (this.isset(this.report) && this.isset(this.report.bitumen)) {
          let item = this.report.bitumen.find((x) => x.id == id);
          return (
            "R" +
            item.element_number +
            (item.element_level == 0 ? "" : "." + String(item.element_level))
          );
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },
    reasonName(id) {
      return this.findTextList(id, this.listReason);
    },
    equipmentName(equipment) {
      if(!equipment) return '';
      let items = this.$_.cloneDeep(this.storeListEquipment);
      let listEquitments = this.$_.find(items, {
        id: equipment
      });
      return listEquitments?.name;
    }
  },
  computed: {
    temperatureUnit() {
      return this.crusherFormat.temperature == 1 ? " ºC" : " ºF";
    },
  },
  watch: {
    async showDialog(isOpen) {
      const self = this;
      if (isOpen === true) {
        this.report = this.$store.getters["ore/getReportSelected"];
        this.allData = this.report.start_stop_events;
        this.listReason = this.listTranslate(await getListItemsByCode("REL"));
      }
    },
  },
};
</script>
<style  lang="scss" scoped>
.col_custom {
  text-transform: capitalize;
    text-wrap: nowrap;
}
</style>