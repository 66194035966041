<template>
  <div>
    <exp-ore-loading
      :message="$t('ore.comun.report_data')"
      v-if="loadingReport"
    />
    <exp-report-header
      :color="color"
      :code="''"
      :disabledBtnReport="storeOnlyReadReport"
    />
    <v-card elevation="0" :color="'transparent'" class="box-summary">
      <v-card-text class="pa-2">
        <v-row dense>
          <template v-for="moduleSummary in modulesSummaryRender">
            <v-col
              v-if="moduleSummary.code != 'MCMS'"
              :key="moduleSummary.id"
              cols="6"
              class="mb-2"
            >
              <exp-summary-box
                :bgcolorTitle="moduleSummary.color"
                :bgcolor="moduleSummary.color_soft"
                :iconType="moduleSummary.icon_svg != '' ? 'svg' : 'icon'"
                :icon="
                  moduleSummary.icon_svg != ''
                    ? moduleSummary.icon_svg
                    : moduleSummary.icon
                "
                :title="$t('ore.report.' + moduleSummary.name)"
                :items="moduleSummary.summaryCalcs"
              >
              </exp-summary-box>
            </v-col>
            <v-col
              v-else-if="showBoxComments"
              cols="6"
              class="mb-2"
            >
              <exp-summary-box
                :bgcolorTitle="moduleCommentsSummary.color"
                :bgcolor="moduleCommentsSummary.color_soft"
                :iconType="moduleCommentsSummary.icon_svg != '' ? 'svg' : 'icon'"
                :icon="
                  moduleCommentsSummary.icon_svg != ''
                    ? moduleCommentsSummary.icon_svg
                    : moduleCommentsSummary.icon
                "
                :title="$t('ore.report.comments')"
                :items="[]"
                :haveZoom="false"
              >
                <div class="pa-3">
                  <v-btn
                    v-show="btnStarStopEvents"
                    large
                    block
                    @click="openEvents()"
                    class="my-2"
                    >{{ $t("ore.report.events") + `: ${totalEvents}` }}</v-btn
                  >
                  <v-btn
                    v-show="btnComments"
                    large
                    block
                    @click="openComments()"
                  >{{ $t("ore.report.comments") + `: ${totalComments}` }}</v-btn>
                </div>
              </exp-summary-box>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
    <modal-comments ref="modalComments" />
    <modal-events ref="modalEvents" />
  </div>
</template>

<script>
import basicMixin from "@/mixins/basic";
import customerMixin from "@/mixins/customer";
import oreMixin from "@/mixins/ore";
import expSummaryBox from "@/components/expSummaryBoxV2";
import expOreLoading from "@/components/expOreLoading";

import modalComments from "./modal_comments.vue"
import modalEvents from "./modal_events.vue"

export default {
  name: "page-reports-summary",
  layout: "colas",
  mixins: [basicMixin, customerMixin, oreMixin],
  components: {
    expSummaryBox,
    expOreLoading,
    modalComments,
    modalEvents,
  },
  data() {
    return {
      color: "",
      html2: ``,
    };
  },
  methods: {
    openComments() {
      this.$refs.modalComments.showDialog = true;
    },
    openEvents() {
      this.$refs.modalEvents.showDialog = true;
    },
  },
  computed: {
    loadingReport() {
      return this.$store.getters["ore/getLoadingData"]("report");
    },
    modulesSummaryRender() {
      return this.modulesSummary.filter(x => x.showSummary == true && x.code != 'MCM' && x.code != 'MSM')
    },
    modulesSummary() {
      let summaryReport = this.$store.getters["ore/getSummaryReport"]();
      return summaryReport.filter(x => x.showSummary == true);
    },
    btnStarStopEvents(){
      let _start_stop = this.modulesSummary.filter(x => x.code == "MST");
      return (_start_stop && _start_stop.length > 0 ? _start_stop[0]?.settings?.summary?.events == 0 : 0)
    },
    btnComments(){
      let _comments = this.modulesSummary.filter(x => x.code == "MCM");
      return ((_comments && _comments.length > 0) ? _comments[0]?.settings?.summary?.comments == 0 : 0 )
    },
    showBoxComments(){
      return this.btnStarStopEvents || this.btnComments
    },
    moduleCommentsSummary() {
      return this.modulesSummary.find(x => x.code == "MCMS")
    },
    totalComments() {
      let _data =  this.$store.getters["ore/getReportSelected"];
      let total = 0
      if ("comments" in _data) {
        total = _data.comments.length
      }
      return total
    },
    totalEvents() {
      let _data =  this.$store.getters["ore/getReportSelected"];
      let total = 0
      if ("start_stop_events" in _data) {
        total = _data.start_stop_events.length
      }
      return total
    },
  },
  watch: {
    modulesSummary() {
      this.color = this.modulesSummary[0].color;
    },
  },
};
</script>
<style lang="scss" scoped>
.box-summary.v-card {
  height: 590px;
  display: flex !important;
  flex-direction: column;
}

.box-summary .v-card__text {
  flex-grow: 1;
  overflow: auto;
}

/** style on app mobile */
@media screen and (max-height: 800px) {
  .box-summary.v-card {
    height: calc(100vh - 158px);
  }
}
</style>